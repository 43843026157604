import { ERRORS, logger } from "@rtbhouse-apps/logger-react";
import { config, fetchConfig } from "config";
import errorLogger from "helpers/error-logger";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "components/App";

import "minireset.css";
import "typeface-roboto";
import "./index.css";

(async () => {
  await fetchConfig();

  if (config.sentryEnabled) {
    errorLogger.init({
      dsn: config.sentryDsn,
      environment: config.environment,
      release: config.version,
      ignoreErrors: ["Network request failed", "Failed to fetch", "NetworkError"],
      denyUrls: [/^chrome:\/\//i],
    });
  }

  if (config.guiLoggerEnabled && config.environment !== "e2e") {
    logger.init({
      appName: "panel-creatives-preview",
      host: config.guiLoggerBaseUrl,
      localStorageKey: "@rtbhouse/logger:CreativesPreview",
      debug: config.guiLoggerDebugEnabled,
      environment: config.environment,
      dryRun: config.guiLoggerDryRunEnabled,
      // In case of any warning, send it to the Sentry.
      onWarning: (event, warningTitle, warningMessage) =>
        errorLogger.logWarning(`[GUI Logger] ${warningTitle} - ${warningMessage}. Event "${event}"`),
      onException: (error) => {
        if (error instanceof Error && error.message === ERRORS.unauthorizedResponse) {
          window.location.reload();
        }
        // In case of any other error, send it to the Sentry.
        errorLogger.logError(error);
      },
    });
  }

  const rootElement = document.getElementById("root");
  const root = createRoot(rootElement!);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>,
  );
})();
