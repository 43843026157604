import React, { useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import styled from "styled-components";
import useDeepCompareEffect from "use-deep-compare-effect";

import { LOCAL_FILTERS } from "components/common/local-filters/LocalFilter";
import Chip from "components/forms/Chip";
import { ChipsMultiple, ChipsSingle, Option } from "components/forms/Chips";
import Text from "components/forms/Text";
import {
  CommonParameters,
  Creative as CreativeData,
  CreativeFeatures,
  flattenCreativesChoosableRenderingOptions,
  getChoosableRenderingOption,
  getCreatives,
  RenderingOptions,
  Size,
  translateChoosableRenderingOptionsName,
} from "repo/CreativeData";

import ClipboardButton from "./ClipboardButton";
import Creative, { VARIABLE_OFFERS_NUMBER } from "./Creative";
import CreativesAdlookRedirectLink from "./CreativesAdlookRedirectLink";
import ListView from "./ListView";
import Loader from "./Loader";

export interface LocalParameters {
  sizesFilter: Size[] | undefined;
  offersNumberFilter: (number | null)[] | undefined;
  maxPreviews: number | null | undefined; // null - no filter, undefined = default
  renderingOptions: RenderingOptions | undefined;
  isPaapi?: boolean;
  features?: Partial<CreativeFeatures>;
}

export interface CreativesFilters {
  commonParameters: CommonParameters;
  sizes: Size[] | undefined;
  localParameters: LocalParameters;
}

const MAX_PREVIEWS_DEFAULT = 3;

const Creatives: React.FC<{
  initialCreatives: CreativeData[];
  creativesFilters: CreativesFilters;
  updateCreativesFilters: (filters: CreativesFilters) => void;
  packIdentifiers?: string[];
}> = (props) => {
  const { initialCreatives, creativesFilters, updateCreativesFilters, packIdentifiers } = props;

  const creativeHashes = initialCreatives.map((creative) => creative.hash);

  const [hashFilter, setHashFilter] = useState<string | undefined>(undefined);
  const sizesFilter = buildSizesFilter(initialCreatives);
  const maxPreviews = calculateMaxPreviews(initialCreatives);

  const offersNumbersFilter = buildOfferNumbersFilter(initialCreatives);
  const choosableRenderingOptions = flattenCreativesChoosableRenderingOptions(
    initialCreatives.map((creative) => creative.choosableRenderingOptions),
  );

  const [creatives, setCreatives] = useState<CreativeData[] | null>(null);
  const { showBoundary } = useErrorBoundary();

  useDeepCompareEffect(() => {
    setCreatives(null);
    getCreatives(
      creativeHashes,
      creativesFilters.commonParameters,
      creativesFilters.sizes,
      creativesFilters.localParameters.renderingOptions || {},
    ).then((newCreatives) => setCreatives(newCreatives), showBoundary);
  }, [
    creativeHashes,
    creativesFilters.commonParameters,
    creativesFilters.sizes,
    creativesFilters.localParameters.renderingOptions,
  ]);

  return (
    <Wrapper>
      <CreativesAdlookRedirectLink creatives={initialCreatives} />
      <Filters>
        {packIdentifiers !== undefined ? (
          <>
            <FilterLabel>Pack Identifiers:</FilterLabel>
            <FilterControlWrapper>
              {packIdentifiers.map((packIdentifier) => (
                <PackIdentifierWrapper key={packIdentifier}>
                  {packIdentifier} <ClipboardButton value={packIdentifier} />
                </PackIdentifierWrapper>
              ))}
            </FilterControlWrapper>
          </>
        ) : null}
        <FilterLabel>Filter by hash:</FilterLabel>
        <FilterControlWrapper>
          <Text
            placeholder="Creative Hash"
            width={300}
            value={hashFilter || ""}
            valueChanged={(newHashFilter) => setHashFilter(newHashFilter || undefined)}
          />
        </FilterControlWrapper>

        {(creativesFilters.localParameters.sizesFilter || sizesFilter.length > 1) && (
          <>
            <FilterLabel>Sizes to show:</FilterLabel>
            <FilterControlWrapper>
              <FilterControlRow>
                <FilterControlRowActions>
                  <Chip
                    text="All"
                    $enabled
                    enabledChanged={() => {
                      const newSizes = undefined;

                      const localParameters = {
                        ...creativesFilters.localParameters,
                        sizesFilter: newSizes,
                      };
                      const newCommonLocalParametersSizes = {
                        ...creativesFilters,
                        localParameters,
                      };
                      updateCreativesFilters(newCommonLocalParametersSizes);
                    }}
                  />
                  <Chip
                    text="Toggle"
                    $enabled
                    enabledChanged={() => {
                      let newSizes = [] as Size[];

                      if (creativesFilters.localParameters.sizesFilter !== undefined) {
                        newSizes = sizesFilter
                          .filter(
                            (sizeFilter) =>
                              !creativesFilters.localParameters.sizesFilter!.some(
                                (sizeFilterParameter) =>
                                  sizeFilterParameter.width === sizeFilter.width &&
                                  sizeFilterParameter.height === sizeFilter.height,
                              ),
                          )
                          .map(({ width, height }) => ({ width, height }));
                      }

                      const newSizesFilter = newSizes.length === sizesFilter.length ? undefined : newSizes;

                      const localParameters = {
                        ...creativesFilters.localParameters,
                        sizesFilter: newSizesFilter,
                      };
                      const newCommonLocalParametersSizes = {
                        ...creativesFilters,
                        localParameters,
                      };
                      updateCreativesFilters(newCommonLocalParametersSizes);
                    }}
                  />
                  <Chip
                    text="None"
                    $enabled
                    enabledChanged={() => {
                      const newSizes = [] as Size[];

                      const localParameters = {
                        ...creativesFilters.localParameters,
                        sizesFilter: newSizes,
                      };
                      const newCommonLocalParametersSizes = {
                        ...creativesFilters,
                        localParameters,
                      };
                      updateCreativesFilters(newCommonLocalParametersSizes);
                    }}
                  />
                </FilterControlRowActions>
                <FilterControlRowOptions>
                  <ChipsMultiple
                    options={sizesFilter.map((sizeFilter) => ({
                      key: `${sizeFilter.width}x${sizeFilter.height}`,
                      text: `${sizeFilter.width}x${sizeFilter.height} (${sizeFilter.count})`,
                    }))}
                    keysEnabled={sizesFilter
                      .filter((sizeFilter) => {
                        return (
                          creativesFilters.localParameters.sizesFilter === undefined ||
                          creativesFilters.localParameters.sizesFilter.some(
                            (sizeFilterParameter) =>
                              sizeFilterParameter.width === sizeFilter.width &&
                              sizeFilterParameter.height === sizeFilter.height,
                          )
                        );
                      })
                      .map((size) => `${size.width}x${size.height}`)}
                    keysEnabledChanged={(newKeys) => {
                      const newSizes = sizesFilter
                        .filter((sizeFilter) => newKeys.includes(`${sizeFilter.width}x${sizeFilter.height}`))
                        .map(({ width, height }) => ({ width, height }));

                      const newSizesFilter = newSizes.length === sizesFilter.length ? undefined : newSizes;

                      const localParameters = {
                        ...creativesFilters.localParameters,
                        sizesFilter: newSizesFilter,
                      };
                      const newCommonLocalParametersSizes = {
                        ...creativesFilters,
                        localParameters,
                      };
                      updateCreativesFilters(newCommonLocalParametersSizes);
                    }}
                  />
                </FilterControlRowOptions>
              </FilterControlRow>
            </FilterControlWrapper>
          </>
        )}

        {(creativesFilters.localParameters.offersNumberFilter || offersNumbersFilter.length > 1) && (
          <>
            <FilterLabel>Offers number:</FilterLabel>
            <FilterControlWrapper>
              <FilterControlRow>
                <FilterControlRowActions>
                  <Chip
                    text="All"
                    $enabled
                    enabledChanged={() => {
                      const localParameters = {
                        ...creativesFilters.localParameters,
                        offersNumberFilter: undefined,
                      };
                      updateCreativesFilters({ ...creativesFilters, localParameters });
                    }}
                  />
                  <Chip
                    text="Toggle"
                    $enabled
                    enabledChanged={() => {
                      let newOffersNumberFilter: (number | null)[] | undefined = [];
                      if (creativesFilters.localParameters.offersNumberFilter !== undefined) {
                        newOffersNumberFilter = offersNumbersFilter
                          .filter(
                            ({ offersNumber }) =>
                              !creativesFilters.localParameters.offersNumberFilter!.includes(offersNumber),
                          )
                          .map(({ offersNumber }) => offersNumber);
                      }
                      if (newOffersNumberFilter.length === offersNumbersFilter.length) {
                        newOffersNumberFilter = undefined;
                      }

                      const localParameters = {
                        ...creativesFilters.localParameters,
                        offersNumberFilter: newOffersNumberFilter,
                      };
                      updateCreativesFilters({ ...creativesFilters, localParameters });
                    }}
                  />
                  <Chip
                    text="None"
                    $enabled
                    enabledChanged={() => {
                      const localParameters = { ...creativesFilters.localParameters, offersNumberFilter: [] };
                      updateCreativesFilters({ ...creativesFilters, localParameters });
                    }}
                  />
                </FilterControlRowActions>
                <FilterControlRowOptions>
                  <ChipsMultiple
                    options={offersNumbersFilter.map(({ offersNumber, offersCount }) => ({
                      key: offersNumber,
                      text: `${offersNumber ?? VARIABLE_OFFERS_NUMBER} (${offersCount})`,
                      title: `${offersNumber !== null ? pluralize("offer", offersNumber) : VARIABLE_OFFERS_NUMBER} (${pluralize("creative", offersCount)})`,
                    }))}
                    keysEnabled={offersNumbersFilter
                      .filter(
                        ({ offersNumber }) =>
                          !creativesFilters.localParameters.offersNumberFilter ||
                          creativesFilters.localParameters.offersNumberFilter?.includes(offersNumber),
                      )
                      .map(({ offersNumber }) => offersNumber)}
                    keysEnabledChanged={(newKeys) => {
                      let newOffersNumberFilter = !newKeys ? undefined : (newKeys as (number | null)[]);
                      if (newOffersNumberFilter?.length === offersNumbersFilter.length) {
                        newOffersNumberFilter = undefined;
                      }

                      const localParameters = {
                        ...creativesFilters.localParameters,
                        offersNumberFilter: newOffersNumberFilter,
                      };
                      updateCreativesFilters({ ...creativesFilters, localParameters });
                    }}
                  />
                </FilterControlRowOptions>
              </FilterControlRow>
            </FilterControlWrapper>
          </>
        )}

        {maxPreviews > 3 && (
          <>
            <FilterLabel>Variants to show:</FilterLabel>
            <FilterControlWrapper>
              <ChipsSingle
                options={[
                  { key: null, text: "Any" },
                  { key: 3, text: "3 most popular variants" },
                  { key: 10, text: "10 most popular variants" },
                ]}
                keyEnabled={
                  creativesFilters.localParameters.maxPreviews !== undefined
                    ? creativesFilters.localParameters.maxPreviews
                    : MAX_PREVIEWS_DEFAULT
                }
                keyEnabledChanged={(newMaxPreviews) => {
                  const maxPreviews =
                    newMaxPreviews !== MAX_PREVIEWS_DEFAULT ? (newMaxPreviews as number | null) : undefined;

                  const localParameters = {
                    ...creativesFilters.localParameters,
                    maxPreviews,
                  };
                  const newCommonLocalParametersSizes = {
                    ...creativesFilters,
                    localParameters,
                  };
                  updateCreativesFilters(newCommonLocalParametersSizes);
                }}
              />
            </FilterControlWrapper>
          </>
        )}

        {LOCAL_FILTERS.map((filter) =>
          filter.render({
            initialCreatives,
            creativesFilters,
            updateCreativesFilters,
          }),
        )}

        {Object.entries(choosableRenderingOptions).map(([key, values]) => (
          <React.Fragment key={key}>
            <FilterLabel>{translateChoosableRenderingOptionsName(key)}:</FilterLabel>
            <FilterControlWrapper>
              <ChipsSingle
                options={(
                  [
                    {
                      key: null,
                      text: "Any",
                    },
                  ] as Option[]
                ).concat(values.map((value) => getChoosableRenderingOption(value)).sort((a, b) => a.order - b.order))}
                keyEnabled={(creativesFilters.localParameters.renderingOptions || {})[key] || null}
                keyEnabledChanged={(newValue) => {
                  const renderingOptions = { ...(creativesFilters.localParameters.renderingOptions || {}) };
                  if (newValue !== null) {
                    renderingOptions[key] = newValue as string;
                  } else {
                    delete renderingOptions[key];
                  }

                  const localParameters = {
                    ...creativesFilters.localParameters,
                    renderingOptions: renderingOptions,
                  };
                  const newCommonLocalParametersSizes = {
                    ...creativesFilters,
                    localParameters,
                  };
                  updateCreativesFilters(newCommonLocalParametersSizes);
                }}
              />
            </FilterControlWrapper>
          </React.Fragment>
        ))}
      </Filters>

      {creatives !== null ? (
        <Previews>
          <ListView
            dataItems={filterCreatives(creatives, {
              hash: hashFilter,
              creativesFilters,
            })}
            keyResolver={(creative: CreativeData) => creative.hash}
            componentRenderer={(creative: CreativeData) => (
              <Creative
                creative={creative}
                maxPreviews={
                  creativesFilters.localParameters.maxPreviews !== undefined
                    ? creativesFilters.localParameters.maxPreviews
                    : MAX_PREVIEWS_DEFAULT
                }
              />
            )}
            pageSize={10}
          />
        </Previews>
      ) : (
        <Loader />
      )}
    </Wrapper>
  );
};

export default Creatives;

function filterCreatives(
  creatives: CreativeData[],
  { hash, creativesFilters }: { hash?: string; creativesFilters: CreativesFilters },
): CreativeData[] {
  return creatives
    .filter((creative) => hash === undefined || creative.hash === hash)
    .map((creative) => ({
      ...creative,
      previews: creative.previews
        .filter(
          (preview) =>
            creativesFilters.localParameters.sizesFilter === undefined ||
            creativesFilters.localParameters.sizesFilter.some(
              (size) => size.width === preview.width && size.height === preview.height,
            ),
        )
        .filter(
          (preview) =>
            !creativesFilters.localParameters.offersNumberFilter ||
            creativesFilters.localParameters.offersNumberFilter.includes(preview.offersNumber),
        )
        .map((preview) => ({ ...preview })),
    }))
    .filter((creative) => creative.previews.length > 0)
    .filter((creative) => LOCAL_FILTERS.every((filter) => filter.filterCreatives(creative, creativesFilters)));
}

function buildSizesFilter(creatives: CreativeData[]): { width: number; height: number; count: number }[] {
  const sizesFilter = new Map<string, { width: number; height: number; count: number }>();
  creatives.forEach((creative) => {
    creative.previews.forEach((preview) => {
      const sizeKey = `${preview.width}x${preview.height}`;
      if (!sizesFilter.has(sizeKey)) {
        sizesFilter.set(sizeKey, { width: preview.width, height: preview.height, count: 0 });
      }
      sizesFilter.get(sizeKey)!.count += 1;
    });
  });

  return Array.from(sizesFilter.entries())
    .sort(([, sizesFilterA], [, sizesFilterB]) => sizesFilterB.count - sizesFilterA.count)
    .map(([, sizesFilter]) => sizesFilter);
}

function calculateMaxPreviews(creatives: CreativeData[]): number {
  return Math.max(...creatives.map((creative) => creative.previews.length), 0);
}

function buildOfferNumbersFilter(creatives: CreativeData[]): { offersNumber: number | null; offersCount: number }[] {
  const offersNumberWithCount = creatives.reduce<Map<number | null, number>>((acc, { previews }) => {
    previews.forEach(({ offersNumber }) => acc.set(offersNumber, (acc.get(offersNumber) ?? 0) + 1));
    return acc;
  }, new Map());

  return Array.from(offersNumberWithCount.entries())
    .sort(([a], [b]) => (a ?? Infinity) - (b ?? Infinity))
    .map(([offersNumber, offersCount]) => ({ offersNumber, offersCount }));
}

function pluralize(word: string, count: number): string {
  return `${count} ${word}${count > 1 ? "s" : ""}`;
}

const Wrapper = styled.div`
  padding: 1rem;

  @media (max-width: 640px) {
    padding: 0.5rem;
  }
`;

const Filters = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-rows: max-content;
  align-items: center;
  margin-bottom: 1rem;

  color: #656565;

  @media (max-width: 640px) {
    grid-template-columns: auto;
  }
`;

export const FilterLabel = styled.div`
  margin: 1rem 1rem 1rem 0;

  font-weight: bold;

  @media (max-width: 640px) {
    margin: 0 0 0.5rem;
  }
`;

export const FilterControlWrapper = styled.div`
  margin: 0.5rem;
  @media (max-width: 640px) {
    margin: 0 0 1rem;
  }
`;

const PackIdentifierWrapper = styled.div`
  display: inline-block;
  margin-right: 1rem;
`;

const Previews = styled.div``;

const FilterControlRow = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-rows: max-content;
  align-items: center;

  & > * {
    border-left: solid 1px #cccccc;
    margin-left: 0.5rem;
    padding-left: 0.5rem;

    &:first-child {
      border-left: none;
      margin-left: 0;
      padding-left: 0;
    }
  }

  @media (max-width: 640px) {
    grid-template-columns: auto;

    & > * {
      border-left: none;
      margin-left: 0;
      padding-left: 0;

      border-top: solid 1px #cccccc;
      padding-top: 0.5rem;
      margin-top: 0.5rem;

      &:first-child {
        border-top: none;
        padding-top: 0;
        margin-top: 0;
      }
    }
  }
`;

const FilterControlRowActions = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-left: 0.5rem;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const FilterControlRowOptions = styled.div`
  flex: auto;
`;
