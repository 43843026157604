import { FilterControlWrapper, FilterLabel } from "components/common/Creatives";
import { LocalFilter } from "components/common/local-filters/LocalFilter";
import { ChipsSingle } from "components/forms/Chips";

export const FeaturesAnimationFilter: LocalFilter = {
  render: ({ initialCreatives, creativesFilters, updateCreativesFilters }) => {
    const creativesHasAnimationFeature = initialCreatives.some((creative) => !!creative.features?.animation);
    const isFilterActive = !!creativesFilters.localParameters.features?.animation;

    if (!creativesHasAnimationFeature && !isFilterActive) {
      return null;
    }

    const animations = [
      ...new Set(
        initialCreatives
          .filter((creative) => creative.features?.animation)
          .map((creative) => creative.features?.animation ?? ""),
      ),
    ];
    const value = creativesFilters.localParameters.features?.animation ?? null;

    return (
      <>
        <FilterLabel>Animation type:</FilterLabel>
        <FilterControlWrapper>
          <ChipsSingle
            options={[
              { key: null, text: "Any" },
              ...animations.map((animation) => ({
                key: animation,
                text: animation.replaceAll("_", " "),
              })),
            ]}
            keyEnabled={value}
            keyEnabledChanged={(key) => {
              updateCreativesFilters({
                ...creativesFilters,
                localParameters: {
                  ...creativesFilters.localParameters,
                  features: {
                    ...creativesFilters.localParameters.features,
                    animation: key ? `${key}` : undefined,
                  },
                },
              });
            }}
          />
        </FilterControlWrapper>
      </>
    );
  },
  filterCreatives: (creative, creativesFilters) => {
    if (creativesFilters.localParameters.features?.animation === undefined) {
      return true;
    }

    return creative.features?.animation === creativesFilters.localParameters.features.animation;
  },
  searchFromLocalParameters: (search, localParameters) => {
    if (localParameters.features?.animation) {
      search.set("features_animation", localParameters.features.animation);
    }

    return search;
  },
  searchToLocalParameters: (search, localParameters) => {
    const animation = search.get("features_animation");

    if (animation === null) {
      return localParameters;
    }

    return {
      ...localParameters,
      features: {
        ...localParameters.features,
        animation,
      },
    };
  },
};
