import { CreativesFilters, LocalParameters } from "components/common/Creatives";
import { FeaturesAnimationFilter } from "components/common/local-filters/FeaturesAnimationFilter";
import { createFeaturesBooleanFilter } from "components/common/local-filters/FeaturesBooleanFilter";
import { PaapiFilter } from "components/common/local-filters/PaapiFilter";
import { Creative as CreativeData } from "repo/CreativeData";

type RenderProps = {
  initialCreatives: CreativeData[];
  creativesFilters: CreativesFilters;
  updateCreativesFilters: (filters: CreativesFilters) => void;
};

export type LocalFilter = {
  render: (props: RenderProps) => React.ReactNode;
  filterCreatives: (creative: CreativeData, creativesFilters: CreativesFilters) => boolean;
  searchFromLocalParameters: (search: URLSearchParams, localParameters: LocalParameters) => URLSearchParams;
  searchToLocalParameters: (search: URLSearchParams, localParameters: LocalParameters) => LocalParameters;
};

export const LOCAL_FILTERS: LocalFilter[] = [
  PaapiFilter,
  FeaturesAnimationFilter,
  createFeaturesBooleanFilter("name"),
  createFeaturesBooleanFilter("button"),
  createFeaturesBooleanFilter("price"),
];
