import { config } from "config";

export async function get<T>(endpoint: string): Promise<T> {
  const response = await fetch(config.apiHost + endpoint, {
    headers: {
      Accept: "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(`get() failed: ${response.status} ${response.statusText}`);
  }

  const content = await response.json();

  if (typeof content !== "object") {
    throw new Error("response content is not an object");
  }

  if (content["data"] === undefined) {
    throw new Error("data field missing in content");
  }

  return content["data"];
}
