import type { Config, Environment } from "@rtbhouse-apps/commonlib";
import { config as cfg, fetchConfig as fetchCfg } from "@rtbhouse-apps/commonlib/dist/config-repo";

type PanelConfig = {
  environment: Environment;
  apiHost?: string;
  apiOnSameHost?: boolean;
  version: string;
  guiLoggerEnabled: boolean;
  guiLoggerBaseUrl: string;
  guiLoggerDebugEnabled: boolean;
  guiLoggerDryRunEnabled: boolean;
  sentryEnabled: boolean;
  sentryDsn: string;
};

export const config = cfg as Config<PanelConfig>;

export async function fetchConfig() {
  return (await fetchCfg<PanelConfig>("/config.json")) as Config;
}
