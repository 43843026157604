import styled from "styled-components";

import { FilterControlWrapper, FilterLabel } from "components/common/Creatives";
import { LocalFilter } from "components/common/local-filters/LocalFilter";
import { ChipsSingle } from "components/forms/Chips";
import { CreativeFeatures } from "repo/CreativeData";
import { parseBooleanParameter, serializeBooleanParameter } from "util/UrlSearchParamsHelpers";

export const createFeaturesBooleanFilter = (
  name: keyof Pick<CreativeFeatures, "name" | "button" | "price">,
): LocalFilter => ({
  render: ({ initialCreatives, creativesFilters, updateCreativesFilters }) => {
    const creativesHasBooleanFeature = initialCreatives.some((creative) => !!creative.features?.[name]);
    const isFilterActive = !!creativesFilters.localParameters.features?.[name];

    if (!creativesHasBooleanFeature && !isFilterActive) {
      return null;
    }

    const value =
      creativesFilters.localParameters.features?.[name] !== undefined
        ? serializeBooleanParameter(creativesFilters.localParameters.features[name])
        : null;

    return (
      <>
        <Label>{name}:</Label>
        <FilterControlWrapper>
          <ChipsSingle
            options={[
              { key: null, text: "Any" },
              { key: "true", text: "Enabled" },
              { key: "false", text: "Disabled" },
            ]}
            keyEnabled={value}
            keyEnabledChanged={(key) => {
              updateCreativesFilters({
                ...creativesFilters,
                localParameters: {
                  ...creativesFilters.localParameters,
                  features: {
                    ...creativesFilters.localParameters.features,
                    [name]: key ? parseBooleanParameter(`${key}`) : undefined,
                  },
                },
              });
            }}
          />
        </FilterControlWrapper>
      </>
    );
  },
  filterCreatives: (creative, creativesFilters) => {
    if (creativesFilters.localParameters.features?.[name] === undefined) {
      return true;
    }

    return creative.features?.[name] === creativesFilters.localParameters.features[name];
  },
  searchFromLocalParameters: (search, localParameters) => {
    if (localParameters.features?.[name] !== undefined) {
      search.set(`features_${name}`, serializeBooleanParameter(localParameters.features[name]));
    }

    return search;
  },
  searchToLocalParameters: (search, localParameters) => {
    const feature = search.get(`features_${name}`);

    if (feature === null) {
      return localParameters;
    }

    return {
      ...localParameters,
      features: {
        ...localParameters.features,
        [name]: parseBooleanParameter(feature),
      },
    };
  },
});

const Label = styled(FilterLabel)`
  text-transform: capitalize;
`;
