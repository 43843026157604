import { useLogNavigation } from "@rtbhouse-apps/logger-react";
import React, { useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import useDeepCompareEffect from "use-deep-compare-effect";

import Creatives, { CreativesFilters } from "components/common/Creatives";
import Loader from "components/common/Loader";
import useDocumentTitle from "hooks/document-title";
import { Creative, getPacks } from "repo/CreativeData";

const CreativesByPackIdentifier: React.FC<{
  advertiserHash: string;
  packIdentifiers: string[];
  creativesFilters: CreativesFilters;
  updateCreativesFilters: (filters: CreativesFilters) => void;
}> = (props) => {
  const { advertiserHash, packIdentifiers, creativesFilters, updateCreativesFilters } = props;

  useDocumentTitle("Creatives by pack identifiers");
  useLogNavigation("creatives by pack identifiers");

  const [initialCreatives, setInitialCreatives] = useState<Creative[] | null>(null);
  const { showBoundary } = useErrorBoundary();

  useDeepCompareEffect(() => {
    setInitialCreatives(null);
    getPacks(advertiserHash, packIdentifiers, creativesFilters.commonParameters, creativesFilters.sizes, {}).then(
      (newCreatives) => setInitialCreatives(newCreatives),
      showBoundary,
    );
  }, [advertiserHash, packIdentifiers, creativesFilters.commonParameters, creativesFilters.sizes]);

  if (initialCreatives === null) {
    return <Loader />;
  }

  return (
    <Creatives
      initialCreatives={initialCreatives}
      creativesFilters={creativesFilters}
      updateCreativesFilters={updateCreativesFilters}
      packIdentifiers={packIdentifiers}
    />
  );
};
export default CreativesByPackIdentifier;
