import { FilterControlWrapper, FilterLabel } from "components/common/Creatives";
import { LocalFilter } from "components/common/local-filters/LocalFilter";
import { ChipsSingle } from "components/forms/Chips";
import { parseBooleanParameter, serializeBooleanParameter } from "util/UrlSearchParamsHelpers";

export const PaapiFilter: LocalFilter = {
  render: ({ initialCreatives, creativesFilters, updateCreativesFilters }) => {
    const creativesHasPaapi = initialCreatives.some((creative) => creative.isPaapi === true);
    const isFilterActive = !!creativesFilters.localParameters.isPaapi;

    if (!creativesHasPaapi && !isFilterActive) {
      return null;
    }

    const value =
      creativesFilters.localParameters.isPaapi !== undefined
        ? serializeBooleanParameter(creativesFilters.localParameters.isPaapi)
        : null;

    return (
      <>
        <FilterLabel>Banner type:</FilterLabel>
        <FilterControlWrapper>
          <ChipsSingle
            options={[
              { key: null, text: "Any" },
              { key: "false", text: "Classic" },
              { key: "true", text: "PA API" },
            ]}
            keyEnabled={value}
            keyEnabledChanged={(key) => {
              updateCreativesFilters({
                ...creativesFilters,
                localParameters: {
                  ...creativesFilters.localParameters,
                  isPaapi: key ? parseBooleanParameter(`${key}`) : undefined,
                },
              });
            }}
          />
        </FilterControlWrapper>
      </>
    );
  },
  filterCreatives: (creative, creativesFilters) => {
    if (creativesFilters.localParameters.isPaapi === undefined) {
      return true;
    }

    return creative.isPaapi === creativesFilters.localParameters.isPaapi;
  },
  searchFromLocalParameters: (search, localParameters) => {
    if (localParameters.isPaapi !== undefined) {
      search.set("is_paapi", serializeBooleanParameter(localParameters.isPaapi));
    }

    return search;
  },
  searchToLocalParameters: (search, localParameters) => {
    const isPaapi = search.get("is_paapi");

    if (isPaapi === null) {
      return localParameters;
    }

    return {
      ...localParameters,
      isPaapi: parseBooleanParameter(isPaapi),
    };
  },
};
